@use '@styles/config' as *;

.completed-lesson {
    margin-left: 125px;
    background: #f8f8f8;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    border-radius: 20px;
    padding: 40px 105px 15px 105px;
    &-loading {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 95vh;
    }
    &-header {
        &-burger {
            background: transparent;
            border: none;
            cursor: pointer;
            margin-left: 14px;
        }
    }
    &-title {
        text-align: center;
        margin-bottom: 40px;
        margin-top: 30px;
        h1 {
            font-size: 20px;
            font-weight: 500;
            line-height: 23px;
            text-transform: uppercase;
        }
    }

    &-head {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    &__info {
        display: flex;
        flex-direction: column;
        &-profile {
            display: flex;
            align-items: center;
            margin-bottom: 32px;

            &__avatar {
                overflow: hidden;
                width: 3.75rem;
                height: 3.75rem;
                border-radius: 50%;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            p {
                color: #666666;
                font-size: 16px;
                line-height: 18px;
                font-weight: 500;
                margin-left: 23px;
                span {
                    display: block;
                    line-height: 18px;
                }
            }
        }
        &-desc {
            display: flex;
            justify-content: space-between;
            div {
                &:first-child {
                    font-weight: 300;
                    font-size: 12px;
                    line-height: 24px;
                    letter-spacing: 0.01em;
                    color: #a09f9f;
                }
                &:last-child {
                    margin-left: 23px;

                    font-weight: 500;
                    font-size: 13px;
                    line-height: 24px;
                    letter-spacing: 0.01em;
                    color: #666666;
                }
            }
        }
        &-button {
            margin-top: 35px;
            margin-bottom: 22px;
            display: none;
            button {
                font-size: 16px;
                padding: 15px 39px 16px 39px;
                line-height: 19px;
            }
        }
    }
    &__records {
        background: #127050;
        border-radius: 10px;
        padding: 46px 57px 21px 57px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        max-height: 212px;

        div {
            img {
                cursor: pointer;
                max-height: 80px;
            }
            p {
                margin-top: 31px;
                color: #fff;
                font-size: 12px;
                font-weight: 500;
            }
        }
        &-lesson {
            cursor: pointer;

            // margin-right: 129px;
            img {
                max-height: 80px;
            }
        }
        &-board {
            img {
                max-height: 80px;
            }
        }
    }
    &__chat {
        &-record {
            img {
                cursor: pointer;
                max-height: 40px;
            }
            background: #ffffff;
            border-radius: 10px;

            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 25px 64px 37px 63px;
            border-radius: 10px;
            p {
                margin-top: 12px;
                color: #7b7c7c;
                font-size: 12px;
                font-weight: 500;
                line-height: 18px;
            }
        }
        &-callback {
            margin-top: 7px;
            background: #ffeed0;
            border-radius: 10px;
            padding: 16px 20px;
            cursor: pointer;
            div {
                display: flex;
                align-items: center;
            }
            span {
                font-size: 10px;
                font-weight: 500;
            }
            p {
                font-size: 8px;
                margin-top: 7px;
                text-align: center;
                font-weight: 400;
            }
            div {
                img {
                    margin-right: 25px;
                    cursor: pointer;
                }
            }
        }
        &_back-button {
            margin-top: 15px;
            button {
                background-color: #2e9144;
                cursor: pointer;
                border: none;
                color: #fff;
                padding: 15px;
                border-radius: 5px;
                width: 100%;
                &:hover {
                    background-color: #316b3f;
                }
                &:active {
                    background-color: #295231;
                }
            }
        }
    }
    &__rating {
        display: flex;
        flex-direction: column;
        align-items: center;
        border: 1px solid #f9a61a;
        box-sizing: border-box;
        border-radius: 10px;
        background: #fff;
        padding: 29px 86px 39px 88px;
        &-wrap {
            display: flex;
            justify-content: center;
            margin-top: 45px;
            flex-basis: 100%;
        }
        &-title {
            p {
                font-weight: 500;
                font-size: 16px;
                line-height: 18px;
            }
        }
        &-stars {
            margin-top: 26px;
            margin-bottom: 16px;
            margin-left: 10px;
            span {
                margin-right: 10px;
                span {
                }
                &:last-child {
                    margin-right: 0;
                }
            }
        }
        &-textarea {
            position: relative;
            textarea {
                width: 434px;
                height: 140px;
                padding: 6px 15px;
                border: 1px solid #e4e4e4;
                box-sizing: border-box;
                border-radius: 10px;
                margin-bottom: 21px;
                resize: none;
            }
            &-count {
                position: absolute;
                right: 0;
                bottom: 10px;
                font-size: 10px;
                font-weight: 400;
                color: #88ce80;
                &.error {
                    color: red;
                }
            }
        }
    }
    &__rated {
        flex-basis: 100%;
        margin-left: 550px;
        margin-top: 63px;
        &-title {
            text-align: center;
            p {
                font-size: 14px;
                line-height: 16px;
                font-weight: 500;
            }
        }
        &-stars {
            align-self: flex-start;
            margin-top: 25px;
            margin-bottom: 29px;
            margin-left: 10px;
            span {
                margin-right: 10px;
                &:last-child {
                    margin-right: 0;
                }
            }
        }
        &-text {
            align-self: flex-start;
            text-align: left;
            overflow-wrap: break-word;
            font-size: 11px;
            font-weight: 400;
            max-width: 609px;
        }
    }
    &__notice {
        display: flex;
        flex-direction: column;
        align-items: center;
        border: 1px solid #f9a61a;
        box-sizing: border-box;
        border-radius: 10px;
        background: #fff;
        padding: 29px 86px 39px 88px;

        &-wrap {
            display: flex;
            justify-content: center;
            margin-top: 45px;
            flex-basis: 100%;
        }

        &-title {
            font-weight: 500;
            font-size: 1rem;
            line-height: 1.5rem;
        }
    }
}
@include for-tablet {
    .completed-lesson {
        margin-left: 107px;
        padding: 20px;
        &-title {
            font-size: 18px;
        }
        &__info {
            &-desc {
                justify-content: initial;
                div {
                    &:first-child {
                        margin-right: 10px;
                    }
                }
            }
        }
        &__records {
            flex-wrap: wrap;
            padding: 25px 42px 22px 42px;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            max-height: 100%;

            div {
                text-align: center;
                img {
                    width: 40px;
                    height: 40px;
                }
                p {
                    margin-top: 11px;
                }
                &:first-child {
                    margin-bottom: 29px;
                    margin-right: 0;
                }
            }
        }
        &__rating {
            padding: 26px 17px 19px 17px;

            &-button {
                width: 100%;
                button {
                    width: 100%;
                }
            }
            &-textarea {
                textarea {
                    width: 415px;
                }
            }
            &-stars {
                margin-bottom: 35px;
                margin-top: 21px;
            }
        }
        &__rated {
            margin: 35px auto 0 auto;
            text-align: center;
            &-text {
                max-width: 415px;
                margin: 0 auto;
            }
        }
        &__chat {
            &-record {
                padding-right: 36px;
                padding-left: 36px;
                background: #e5e5e5;
            }
            &-callback {
                padding: 16px 2px;
                margin-top: 13px;
                div {
                    img {
                        margin-right: 10px;
                        margin-left: 15px;
                    }
                }
            }
        }
    }
}
@include for-mobile {
    .completed-lesson {
        margin-left: 0;
        justify-content: center;
        background: #fff;
        padding: 0;
        &-header {
            padding: 0.625rem 0.875rem;
        }
        &-title {
            margin-top: 15px;
            margin-bottom: 19px;
            h1 {
                font-size: 14px;
                text-transform: none;
            }
        }
        &-head {
            flex-wrap: wrap;
            justify-content: center;
        }
        &__records {
            margin-right: 10px;
            padding: 25px 40px 22px 40px;
            order: 3;
        }
        &__chat {
            order: 3;
        }
        &__info {
            align-items: center;
            flex-basis: 100%;
            order: 1;
            &-desc {
                div {
                    &:first-child {
                        margin-right: 20px;
                    }
                }
            }
            &-button {
                display: block;
            }
        }
        &__rating-wrap,
        &__rated {
            display: none;
        }
        &__rating {
            padding: 28px 25px 26px 28px;
            &-wrap {
                order: 2;
                &.mobile-active {
                    display: flex;
                    margin-bottom: 43px;
                }
            }
            &-button {
                width: auto;
            }
            &-textarea {
                textarea {
                    width: 264px;
                }
            }
        }
        &__rated {
            order: 2;
            flex-basis: 100%;
            &-text {
                font-size: 9px;
                max-width: 238px;
            }
            &.mobile-active {
                display: block;
                margin-bottom: 34px;
            }
        }
        &__notice {
            padding: 1rem;

            &-wrap {
                max-width: 320px;
                margin: 0 0 1rem;
            }

            &-title {
                font-size: 14px;
                line-height: 1.25rem;
            }

            &-br {
                display: none;
            }
        }
    }
}
