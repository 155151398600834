.checkbox {
    &__wrapper {
        cursor: pointer;
        display: flex;
        align-items: center;

        &_disabled {
            pointer-events: none;
        }
    }

    &__checkbox {
        display: none;
    }

    &__checkbox:checked + &__label {
        .icon {
            opacity: 1;
        }
    }

    &__label {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 1.563rem;
        height: 1.563rem;
        border: 1px solid #e4e4e4;
        border-radius: 0.313rem;

        .icon {
            opacity: 0;
            transition: all 0.2s ease;
        }
    }
}
