@use '../../styles/config/index' as *;
.complaint {
    &-header {
        padding-right: 0;
        &-burger {
            background: transparent;
            border: none;
            cursor: pointer;
            margin-left: 14px;
        }
    }
    &-title {
        text-align: center;
        margin-bottom: 40px;
        margin-top: 30px;
        h1 {
            font-size: 20px;
            font-weight: 500;
            line-height: 23px;
            text-transform: uppercase;
        }
    }

    &-content {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        min-height: 70vh;
        margin-left: 125px;
        background: #f8f8f8;
        border-radius: 20px;
        padding: 40px 105px 15px 105px;
    }

    &-form {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 70%;
        padding: 2rem 1rem;
        border: 1px solid #f9a61a;
        box-sizing: border-box;
        border-radius: 10px;
        background: #fff;

        &__title {
            p {
                font-weight: 500;
                font-size: 16px;
                line-height: 18px;
            }
        }

        &__checkboxes {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            margin: 1rem 0 2rem;
        }

        &__checkbox {
            display: flex;
            align-items: center;
            margin: 0.5rem 0.5rem 0 0;
            span {
                margin: 0 0 0 0.5rem;
                font-weight: 400;
                font-size: 0.7rem;
                line-height: 1rem;
            }
        }

        &__textarea {
            position: relative;
            width: 100%;

            p {
                margin: 0 0 0.1rem;
                font-weight: 500;
                font-size: 0.65rem;
                line-height: 1rem;
            }

            textarea {
                height: 8.75rem;
                padding: 6px 15px;
                border: 1px solid #e4e4e4;
                box-sizing: border-box;
                border-radius: 10px;
                margin-bottom: 21px;
                resize: none;

                &::-webkit-scrollbar {
                    width: 0.6rem;
                }

                &::-webkit-scrollbar-track {
                    box-shadow: inset 0 0 0.25rem 0.25rem #f5f5f8;
                    border: solid 0.188rem transparent;
                }

                &::-webkit-scrollbar-thumb {
                    box-shadow: inset 0 0 0.25rem 0.25rem #268e55;
                    border: solid 0.188rem transparent;
                    border-radius: 1rem;
                }
            }
            &-count {
                position: absolute;
                right: 0;
                bottom: 10px;
                font-size: 10px;
                font-weight: 400;
                color: #88ce80;
            }
        }

        &__loading {
            display: flex;
            align-items: center;
            height: 40vh;
        }
    }
}

@include for-tablet {
    .complaint {
        &-form {
            width: 90%;

            &__textarea {
                textarea {
                    height: 15rem;
                }
            }
        }
    }
}

@include for-mobile {
    .complaint {
        &-title {
            margin-top: 1rem;
            margin-bottom: 1.25rem;
            h1 {
                font-size: 0.875rem;
                text-transform: none;
            }
        }

        &-content {
            margin: 0;
            padding: 1rem;
            background: transparent;
        }

        &-form {
            width: 100%;

            &__textarea {
                textarea {
                    height: 18rem;
                }
            }
        }
    }
}
