@use '../../styles/config/index' as *;

.modal {
    &__wrapper {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 9000;
    }

    &__bg {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba(60, 60, 60, 0.3);
    }

    &__container {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;

        .separator {
            height: 9.375rem;
            flex-shrink: 0;
        }
    }

    &__window {
        padding-top: 1.25rem;
        background-color: white;
    }

    &__title {
        padding: 0 1.25rem;
        font-size: 2rem;
        font-weight: normal;
        text-align: center;
    }

    &__close {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 100;
        padding: 1rem;
        outline: none !important;
        -webkit-appearance: none !important;
        background-color: transparent;
        border: none;
        cursor: pointer;

        svg {
            width: 2rem;
            height: 2rem;
        }
    }

    &__loading {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 2rem 0;
    }

    &__alert {
        padding: 1.25rem;
        min-width: 18.75rem;
    }

    &__alertText {
        margin-bottom: 1.875rem;
        font-weight: 400;
        text-align: center;

        p {
            white-space: pre-line;
        }
    }

    &__buttons {
        display: flex;
    }
}

@media screen and (max-height: 414px) {
    .modal {
        &__container {
            .separator {
                height: 2rem;
            }
        }
    }
}
